<template>
  <div>
    <a-card title="移动端下载">
      <div style="text-align: center">
        <vue-qr :text="qrURL" :size="200"></vue-qr>

        <div style="width: 100%; color: #777; font-size: 18px; margin-top: 12px">
          请扫描二维码链接或输入 {{ qrURL }}下载安装移动端App。<br />
          打印标签功能需使用指定型号PDA, 请联系客户经理购买。
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import VueQr from "vue-qr";

export default {
  components: {
    VueQr,
  },
  computed: {
    qrURL() {
      return `http://${window.location.host}/download/mes_mobile.apk`;
    },
  },
};
</script>

<style scoped></style>
